// Generated by Framer (cb21a97)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useVariantState, CycleVariantState, getFonts } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";
import { Youtube as YouTube } from "https://framerusercontent.com/modules/NEd4VmDdsxM3StIUbddO/vYIHSp44qmOgHgaaijWz/YouTube.js";
const YouTubeFonts = getFonts(YouTube);

const cycleOrder = ["caY0T8u69"];

const variantClassNames = {caY0T8u69: "framer-v-14vd17h"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {type: "tween", ease: [0.44, 0, 0.56, 1], duration: 0, delay: 0, stiffness: 500, damping: 60, mass: 1}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; video?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "caY0T8u69", video: OUPV_tjXz = "https://youtu.be/smPos0mJvh8", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "caY0T8u69", variant, transitions, variantClassNames, cycleOrder});
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-j84Af", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} layoutId="caY0T8u69" className={cx("framer-14vd17h", className)} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopRightRadius: 10, borderTopLeftRadius: 10, ...style}} transition={transition} layoutDependency={layoutDependency}  background={null} data-framer-name="Variant 1" ref={ref}><motion.div style={{}} layoutId="Q02gqrsHp-container" className="framer-1r1609t-container" transition={transition} layoutDependency={layoutDependency}><YouTube width="100%" height="100%" layoutId="Q02gqrsHp" id="Q02gqrsHp" url={OUPV_tjXz} play="Off" shouldMute={true} thumbnail="High Quality" isRed={false} style={{width: "100%", height: "100%"}}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-j84Af [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-j84Af * { box-sizing: border-box; }", ".framer-j84Af .framer-14vd17h { position: relative; overflow: hidden; will-change: transform; width: 600px; height: 338px; }", ".framer-j84Af .framer-1r1609t-container { position: absolute; right: -2px; bottom: -2px; left: -2px; top: -2px; flex: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 338
 * @framerIntrinsicWidth 600
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"OUPV_tjXz":"video"}
 */
const FramerLXwcOrvrd: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerLXwcOrvrd;

FramerLXwcOrvrd.displayName = "Video";

FramerLXwcOrvrd.defaultProps = {width: 600, height: 338};

addPropertyControls(FramerLXwcOrvrd, {OUPV_tjXz: {type: ControlType.String, title: "Video", defaultValue: "https://youtu.be/smPos0mJvh8"}} as any);

addFonts(FramerLXwcOrvrd, [...YouTubeFonts]);